// eslint-disable-next-line import/prefer-default-export

// HAN
export const defaultDeparture = {
  id: 554,
  icao: 'VVNB',
  iata: 'HAN',
  name: 'Sân bay quốc tế Nội Bài',
  nameEN: 'Nội Bài International Airport',
  city: 'Hà Nội',
  state: 'Ha-Nội',
  country: 'VN',
  elevation: 39,
  lat: 21.22119,
  lon: 105.80718,
  tz: 'Asia/Bangkok',
  displayName: 'Hanoi (HAN), Vietnam',
  countryName: 'Việt Nam',
  countryEnName: 'Vietnam',
  cityName: 'Hà Nội',
  cityEnName: 'Ha Noi',
  airportPermalink: 'noi-bai-international-airport',
  worldRegionName: 'Châu Á',
  worldRegionEnName: 'Asia',
  regionName: 'Northern Vietnam',
  countryLongCode: 'VNM',
  popularity: 16103,
}

// SGN
export const defaultArrival = {
  id: 2520,
  icao: 'VVTS',
  iata: 'SGN',
  name: 'Sân bay quốc tế Tân Sơn Nhất',
  nameEN: 'Tan Son Nhat International Airport',
  city: 'TP. Hồ Chí Minh',
  state: 'Ho-Chi-Minh-City',
  country: 'VN',
  elevation: 33,
  lat: 10.8188,
  lon: 106.65186,
  tz: 'Asia/Ho_Chi_Minh',
  displayName: 'Ho Chi Minh City (SGN), Vietnam',
  countryName: 'Việt Nam',
  countryEnName: 'Vietnam',
  cityName: 'TP. Hồ Chí Minh',
  cityEnName: 'Ho Chi Minh City',
  airportPermalink: null,
  worldRegionName: 'Châu Á',
  worldRegionEnName: 'Asia',
  regionName: 'Southern Vietnam',
  countryLongCode: 'VNM',
  popularity: 14353,
}

export const SORT_ITINERARIES_TYPE = {
  BY_CHEAPEST_FARE: 'CHEAPEST_FARE',
  BY_EXPENSIVEST_FARE: 'EXPENSIVEST_FARE',
  BY_EARLIEST_DEPARTURE: 'EARLIEST_DEPARTURE',
  BY_LATEST_DEPARTURE: 'LATEST_DEPARTURE',
  BY_EARLIEST_ARRIVAL: 'EARLIEST_ARRIVAL',
  BY_LATEST_ARRIVAL: 'LATEST_ARRIVAL',
  BY_SHORTEST_DURATION: 'SHORTEST_DURATION',
  BY_LONGEST_DURATION: 'LONGEST_DURATION',
  BY_AIRLINE: 'AIRLINE',
}

export const BUSINESS_FILTER_REGEX = /Business|First/
export const SKYBOSS_FILTER_REGEX = /SkyBoss/
export const BUSINESS_SKYBOSS_FILTER_REGEX = /Business|SkyBoss|First/

export const SOURCE_AK_ECONOMY_FARE = ['X', 'K', 'E', 'O', 'W', 'Z', 'I', 'A', 'V', 'P', 'L', 'U', 'T', 'Q', 'M', 'Y']

/* 3 TRƯỜNG HỢP KHUYẾN MÃI: ( https://discord.com/channels/1054696448110903327/1338461811363811328 )
- Tất cả các chặng bay nội địa Việt Nam => OW.
- VN đi [TH/MY/SG/ID/PH/LA/JP/KR/HK/TW/IN] => OW, RT => HẠNG: Q, N, R, T, E
- VN đi [CN/E/GB/FR/US/AU/IT] => OW, RT => HẠNG: N, R, T, E
*/

// VN -[TH/MY/SG/ID/PH/LA/JP/KR/HK/TW/IN] => OW, TR
export const listVNToInternationalQNRTE = [
  'NRT',
  'HND',
  'KIX',
  'NGO',
  'FUK',
  'ICN',
  'PUS',
  'TPE',
  'KHH',
  'AXT',
  'FKS',
  'HKD',
  'HIJ',
  'IWK',
  'IZO',
  'KOJ',
  'KKJ',
  'KCZ',
  'KMQ',
  'KMJ',
  'KUH',
  'MYJ',
  'MMB',
  'KMI',
  'NGS',
  'KIJ',
  'OBO',
  'ONJ',
  'OIT',
  'OKJ',
  'OKA',
  'CTS',
  'SDJ',
  'SYO',
  'TAK',
  'TTJ',
  'TOY',
  'UBJ',
  'SIN',
  'BKK',
  'HKT',
  'KUL',
  'CGK',
  'DPS',
  'CNX',
  'CEI',
  'USM',
  'KBV',
  'MNL',
  'SUB',
  'REP',
  'PNH',
  'LPQ',
  'VTE',
  'KOS',
  'DEL',
  'BOM',
  'HKG',
]

// VN -[CN/E/GB/FR/US/AU/IT] => OW, RT
export const listVNToInternationalNRTE = [
  'CAN',
  'PVG',
  'CTU',
  'PEK',
  'SZX',
  'MFM',
  'HRB',
  'KMG',
  'XMN',
  'HAK',
  'HGH',
  'HET',
  'HFE',
  'NKG',
  'NNG',
  'NGB',
  'TEN',
  'XIY',
  'SJW',
  'SHE',
  'TAO',
  'TFU',
  'TSN',
  'CZX',
  'CGO',
  'CKG',
  'JJN',
  'WUX',
  'WUH',
  'CDG',
  'LHR',
  'FRA',
  'QYG',
  'QXB',
  'QXG',
  'XZN',
  'BER',
  'BIQ',
  'BHX',
  'ZFQ',
  'BRE',
  'BES',
  'XIZ',
  'CFE',
  'DRS',
  'DUS',
  'EDI',
  'GLA',
  'HAM',
  'HAJ',
  'ZLN',
  'LBA',
  'LEJ',
  'XDB',
  'LRT',
  'LYS',
  'XYD',
  'MAN',
  'MRS',
  'XRF',
  'MIL',
  'MPL',
  'XPJ',
  'MUC',
  'QJZ',
  'NCL',
  'NCE',
  'ZYN',
  'NUE',
  'PUF',
  'XOP',
  'RNS',
  'ZFJ',
  'FCO',
  'XSH',
  'XWG',
  'STR',
  'TLN',
  'TLS',
  'XHK',
  'MEL',
  'SYD',
  'LAX',
  'SFO',
  'PDX',
  'ATL',
  'DTW',
  'AUS',
  'BOS',
  'CHI',
  'DFW',
  'DEN',
  'HNL',
  'IAH',
  'MIA',
  'MSP',
  'JFK',
  'PHL',
  'SEA',
  'STL',
  'IAD',
]

const classQNRTE = ['Q', 'N', 'R', 'T', 'E']
const classNRTE = ['N', 'R', 'T', 'E']

export function checkDiscountVN1A(segmentList, bookingClassList, bookingType, isDomestic) {
  if (isDomestic) {
    return bookingType === 'OW' && bookingClassList.some(it => classQNRTE.includes(it))
  }

  const checkTripDiscount = (segmentChecker, classList) => segmentList.some(segmentChecker) && bookingClassList.some(it => classList.includes(it))

  if (bookingType === 'OW') {
    if (checkTripDiscount(({ arrival }) => listVNToInternationalNRTE.includes(arrival), classNRTE)) return true
    if (checkTripDiscount(({ arrival }) => listVNToInternationalQNRTE.includes(arrival), classQNRTE)) return true
  }

  if (bookingType === 'RT') {
    if (checkTripDiscount(({ departure, arrival }) => [departure, arrival].some(it => listVNToInternationalNRTE.includes(it)), classNRTE)) return true
    if (checkTripDiscount(({ departure, arrival }) => [departure, arrival].some(it => listVNToInternationalQNRTE.includes(it)), classQNRTE)) return true
  }

  return false
}
